import type { ModelFormat } from '../presets/templates'

export type FieldKind = 'string' | 'number' | 'boolean'
export type FieldType = string | number | boolean

export type Field = {
  name: string
  label: string
  visible: boolean
  type: FieldKind
  list?: string
}

export type Template = {
  _id: string
  name: string
  byline: string
  description: string
  userId: string

  display: string
  introduction: string
  init: string
  loop: string
  history: string
  imagePrompt: string
  imagesEnabled: boolean

  fields: Array<Field>

  /**
   * Placeholders (E.g. {{first_name}}) that are referenced, but not generated by a template.
   * These must be manually filled out by the user. The value is stored in `session.overrides`
   */
  manual: string[]
  lists: Record<string, string[]>
}

export type Session = {
  _id: string
  format: ModelFormat
  userId: string
  customFormat?: {
    user: string
    assistant: string
  }

  gameId?: string // Legacy field

  templateId: string
  presetId?: string

  /** Field value overrides */
  init?: Record<string, string>
  overrides: Record<string, string>
  responses: Response[]
  updated: string
}

export type Response = {
  requestId?: string
  input: string
  response: string
} & Record<string, FieldType>
