{
  "attributes": {
    "accessories": ["flower", "ribbon_1", "ribbon_2", "ribbon_3", "rose_1", "rose_2", "sweatdrop"],
    "back_hair": [
      "bob",
      "long_1",
      "long_2",
      "long_curly",
      "medium",
      "short_1",
      "short_2",
      "short_3",
      "short_curly",
      "shoulder",
      "twin_tail"
    ],
    "blushes": ["blush_1", "blush_2"],
    "body": ["body"],
    "eye_cover_hair": ["side"],
    "eyebrows": [
      "angry_1",
      "angry_2",
      "angry_3",
      "neutral_1",
      "neutral_2",
      "sad_1",
      "sad_2",
      "suprised_2",
      "surprised_1"
    ],
    "eyes": [
      "closed_1",
      "closed_2",
      "closed_3",
      "crying_1",
      "neutral_1",
      "neutral_10",
      "neutral_11",
      "neutral_12",
      "neutral_2",
      "neutral_3",
      "neutral_4",
      "neutral_5",
      "neutral_6",
      "neutral_7",
      "neutral_8",
      "neutral_9",
      "surprised_1",
      "surprised_2",
      "surprised_3"
    ],
    "freckles": ["freckles_1", "freckles_2"],
    "front_hair": [
      "bob",
      "curly",
      "hime_cut",
      "hime_cut_short",
      "long_bangs",
      "middle_part",
      "short_1",
      "short_2",
      "short_3",
      "short_4",
      "side_bangs_1",
      "side_bangs_2",
      "side_curl"
    ],
    "glasses": ["black_glasses", "circle_glasses", "red_glasses"],
    "headbands": ["band_1", "band_2", "band_3", "headphones_up"],
    "headphones_base": ["headphones_base"],
    "mouths": [
      "annoyed_1",
      "annoyed_2",
      "closed_smile_1",
      "closed_smile_2",
      "closed_smile_3",
      "closed_smile_4",
      "closed_smile_5",
      "closed_smile_6",
      "frown_1",
      "frown_2",
      "frown_3",
      "frown_4",
      "frown_5",
      "laugh_1",
      "laugh_2",
      "neutral_1",
      "open_mouth_1",
      "open_mouth_2",
      "open_mouth_3",
      "open_mouth_4",
      "open_mouth_5",
      "open_mouth_6",
      "open_mouth_7",
      "open_small_1",
      "open_small_2",
      "open_small_3",
      "open_smile_1",
      "open_smile_2"
    ],
    "neck": ["apron_1", "apron_2", "choker", "lab_coat", "name_tag"],
    "outfits": [
      "casual_1",
      "casual_2",
      "casual_3",
      "casual_4",
      "fancy",
      "office_worker",
      "pajamas",
      "pe_uniform",
      "school_uniform_1",
      "school_uniform_2",
      "school_uniform_3",
      "shirt_1",
      "shirt_2",
      "summer",
      "swim_1",
      "swim_2",
      "tank_top_1",
      "tank_top_2",
      "towel",
      "turtle_neck_1",
      "turtle_neck_2",
      "wedding",
      "winter_1",
      "winter_2"
    ]
  },
  "accessories": {
    "flower": ["base.png"],
    "ribbon_1": ["base.png"],
    "ribbon_2": ["base.png"],
    "ribbon_3": ["base.png"],
    "rose_1": ["base.png"],
    "rose_2": ["base.png"],
    "sweatdrop": ["highlights.png", "skin.png"]
  },
  "back_hair": {
    "bob": ["base.png", "highlights.png"],
    "long_1": ["base.png", "highlights.png"],
    "long_2": ["base.png", "highlights.png"],
    "long_curly": ["base.png", "highlights.png"],
    "medium": ["base.png", "highlights.png"],
    "short_1": ["base.png", "highlights.png"],
    "short_2": ["base.png", "highlights.png"],
    "short_3": ["base.png", "highlights.png"],
    "short_curly": ["base.png", "highlights.png"],
    "shoulder": ["length-base.png", "length-highlights.png"],
    "twin_tail": ["base.png", "highlights.png"]
  },
  "blushes": {
    "blush_1": ["base.png"],
    "blush_2": ["base.png"]
  },
  "body": {
    "body": ["highlights.png", "skin.png"]
  },
  "eye_cover_hair": {
    "side": ["base.png", "highlights.png", "skin.png"]
  },
  "eyebrows": {
    "angry_1": ["skin.png"],
    "angry_2": ["skin.png"],
    "angry_3": ["skin.png"],
    "neutral_1": ["skin.png"],
    "neutral_2": ["skin.png"],
    "sad_1": ["skin.png"],
    "sad_2": ["skin.png"],
    "suprised_2": ["skin.png"],
    "surprised_1": ["skin.png"]
  },
  "eyes": {
    "closed_1": ["skin.png"],
    "closed_2": ["skin.png"],
    "closed_3": ["base.png", "skin.png"],
    "crying_1": ["base.png", "skin.png", "static.png"],
    "neutral_1": ["base.png", "skin.png", "static.png"],
    "neutral_10": ["base.png", "skin.png", "static.png"],
    "neutral_11": ["base.png", "skin.png", "static.png"],
    "neutral_12": ["base.png", "skin.png", "static.png"],
    "neutral_2": ["base.png", "skin.png", "static.png"],
    "neutral_3": ["base.png", "skin.png", "static.png"],
    "neutral_4": ["base.png", "skin.png", "static.png"],
    "neutral_5": ["base.png", "skin.png", "static.png"],
    "neutral_6": ["base.png", "skin.png", "static.png"],
    "neutral_7": ["base.png", "skin.png", "static.png"],
    "neutral_8": ["base.png", "skin.png", "static.png"],
    "neutral_9": ["base.png", "skin.png", "static.png"],
    "surprised_1": ["base.png", "skin.png", "static.png"],
    "surprised_2": ["base.png", "skin.png", "static.png"],
    "surprised_3": ["base.png", "skin.png", "static.png"]
  },
  "freckles": {
    "freckles_1": ["base.png"],
    "freckles_2": ["base.png"]
  },
  "front_hair": {
    "bob": ["base.png", "highlights.png", "skin.png"],
    "curly": ["base.png", "highlights.png", "skin.png"],
    "hime_cut": ["base.png", "highlights.png", "skin.png"],
    "hime_cut_short": ["base.png", "highlights.png", "skin.png"],
    "long_bangs": ["base.png", "highlights.png", "skin.png"],
    "middle_part": ["base.png", "highlights.png", "skin.png"],
    "short_1": ["base.png", "highlights.png", "skin.png"],
    "short_2": ["base.png", "highlights.png", "skin.png"],
    "short_3": ["base.png", "highlights.png", "skin.png"],
    "short_4": ["base.png", "highlights.png", "skin.png"],
    "side_bangs_1": ["base.png", "highlights.png", "skin.png"],
    "side_bangs_2": ["base.png", "highlights.png", "skin.png"],
    "side_curl": ["base.png", "highlights.png", "skin.png"]
  },
  "glasses": {
    "black_glasses": ["base.png"],
    "circle_glasses": ["base.png"],
    "red_glasses": ["base.png"]
  },
  "headbands": {
    "band_1": ["base.png"],
    "band_2": ["base.png"],
    "band_3": ["base.png"],
    "headphones_up": ["base.png"]
  },
  "headphones_base": {
    "headphones_base": ["base.png"]
  },
  "mouths": {
    "annoyed_1": ["skin.png"],
    "annoyed_2": ["skin.png"],
    "closed_smile_1": ["skin.png"],
    "closed_smile_2": ["skin.png"],
    "closed_smile_3": ["skin.png"],
    "closed_smile_4": ["skin.png"],
    "closed_smile_5": ["skin.png"],
    "closed_smile_6": ["skin.png"],
    "frown_1": ["skin.png"],
    "frown_2": ["skin.png"],
    "frown_3": ["skin.png"],
    "frown_4": ["skin.png"],
    "frown_5": ["skin.png"],
    "laugh_1": ["base.png", "skin.png"],
    "laugh_2": ["base.png", "skin.png"],
    "neutral_1": ["skin.png"],
    "open_mouth_1": ["base.png"],
    "open_mouth_2": ["skin.png", "static.png"],
    "open_mouth_3": ["base.png", "skin.png"],
    "open_mouth_4": ["base.png"],
    "open_mouth_5": ["skin.png", "static.png"],
    "open_mouth_6": ["skin.png", "static.png"],
    "open_mouth_7": ["skin.png", "static.png"],
    "open_small_1": ["base.png", "skin.png"],
    "open_small_2": ["skin.png", "static.png"],
    "open_small_3": ["skin.png", "static.png"],
    "open_smile_1": ["base.png", "skin.png"],
    "open_smile_2": ["skin.png", "static.png"]
  },
  "neck": {
    "apron_1": ["base.png"],
    "apron_2": ["base.png"],
    "choker": ["base.png"],
    "lab_coat": ["base.png"],
    "name_tag": ["base.png"]
  },
  "outfits": {
    "casual_1": ["1-base.png", "1-skin.png", "2-base.png", "2-skin.png"],
    "casual_2": ["1-base.png", "1-skin.png", "2-base.png", "2-skin.png"],
    "casual_3": ["1-base.png", "2-base.png", "3-base.png"],
    "casual_4": ["1-base.png", "1-skin.png"],
    "fancy": ["base.png", "skin.png"],
    "office_worker": ["base.png", "skin.png"],
    "pajamas": ["base.png", "skin.png"],
    "pe_uniform": ["base.png", "skin.png"],
    "school_uniform_1": ["base.png", "skin.png"],
    "school_uniform_2": ["base.png", "skin.png"],
    "school_uniform_3": ["base.png", "skin.png"],
    "shirt_1": ["base.png"],
    "shirt_2": ["base.png"],
    "summer": ["base.png", "skin.png"],
    "swim_1": ["base.png", "skin.png"],
    "swim_2": ["base.png", "highlights.png", "skin.png"],
    "tank_top_1": ["base.png", "skin.png"],
    "tank_top_2": ["base.png", "skin.png"],
    "towel": ["base.png", "skin.png"],
    "turtle_neck_1": ["base.png"],
    "turtle_neck_2": ["base.png"],
    "wedding": ["base.png", "skin.png"],
    "winter_1": ["base.png"],
    "winter_2": ["base.png"]
  }
}
