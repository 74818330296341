import { Component, createEffect, createSignal, For, Show } from 'solid-js'
import PageHeader from '../shared/PageHeader'
import { Page } from '/web/Layout'
import { characterStore, userStore, toastStore } from '../store'
import { AppSchema } from '../../common/types/schema'
import Button from '../shared/Button'
import CharacterCard from '../shared/CharacterCard'

const Charhub: Component = () => {
  const state = characterStore()
  const [hoveredParent, setHoveredParent] = createSignal<string | null>(null)
  const [activeTab, setActiveTab] = createSignal<'official' | 'original' | 'variations'>('official')

  const ADMIN_USER_ID = 'c3bb9f3c-e8ff-46a7-bb99-8dd31042d36f'

  createEffect(() => {
    characterStore.getAllCharacters()
  })

  const importCharacter = async (character: AppSchema.Character) => {
    console.log('Importing character with avatar:', character.avatar)
    try {
      // Step 1: Sanitize character data and perform local creation (first API action)
      const sanitizedCharacter: NewCharacter = {
        ...character,
        scenario: character.scenario || '',
        greeting: character.greeting || '',
        sampleChat: character.sampleChat || '',
        persona: character.persona || { kind: 'text', attributes: {} },
        favorite: character.favorite === true,
        imports: character.imports || [],
        parent: character._id, // Add this line to set the parent field
      }
      console.log('Sanitized character avatar:', sanitizedCharacter.avatar)

      // Execute local character creation
      const localResult = await characterStore.createCharacter(sanitizedCharacter)
      console.log('Local creation result avatar:', localResult.result?.avatar)

      // Notify about the local creation result
      if (localResult.result) {
        toastStore.success(`Successfully imported ${character.name} locally`)
      } else {
        toastStore.error(`Failed to import ${character.name} locally: ${localResult.error}`)
      }

    } catch (error) {
      console.error('Error importing character:', error)
      toastStore.error(`Local import failed for ${character.name}: ${error}`)
    }

    // Step 2: Proceed with server import (second API action) regardless of local import result
    try {
      const { jwt } = userStore.getState()
      if (!jwt) {
        toastStore.error('You must be logged in to import characters to the server')
        return
      }

      // Execute the server-side import
      const serverResponse = await fetch(`/api/character/${character._id}/import`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        }
      })

      // Notify about the server import result
      if (serverResponse.ok) {
        toastStore.success(`Successfully imported ${character.name} to the server`)
      } else {
        const error = await serverResponse.text()
        toastStore.error(`Failed to import ${character.name} to the server: ${error}`)
      }

    } catch (error) {
      toastStore.error(`Server import failed for ${character.name}: ${error}`)
    }
  }

  const originalCharacters = () => state.allCharacters.filter(char => !char.parent && char.userId !== ADMIN_USER_ID)
  const variationCharacters = () => state.allCharacters.filter(char => char.parent)
  const officialCharacters = () => state.allCharacters.filter(char => char.userId === ADMIN_USER_ID && !char.parent)

  const renderCharacterCard = (character: AppSchema.Character) => {
    const isOfficial = character.userId === ADMIN_USER_ID

    const CheckmarkIcon = ({ size = 'w-4 h-4' }) => (
      <svg class={`${size} ml-1`} fill="#00c5ff" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
      </svg>
    )

    const CheckmarkIcon2 = ({ size = 'w-4 h-4' }) => (
      <svg class={`${size} ml-1`} fill="#ff6161" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
      </svg>
    )

    return (
      <div class="bg-gray-800 rounded-lg overflow-hidden flex flex-col">
        {/* Section 1: Image */}
        <div class="relative pb-[100%]">
          <img 
            src={character.avatar} 
            alt={character.name} 
            class="absolute inset-0 w-full h-full object-cover"
          />
        </div>
        
        {/* Section 2: Character name and Import button */}
        <div class="p-4 flex flex-col items-center">
          <h3 class="text-lg font-semibold mb-2 text-center flex items-center">
            {character.name}
            {isOfficial && <CheckmarkIcon />}
          </h3>
          <Button onClick={() => importCharacter(character)} class="bg-red-600 hover:bg-red-700 w-full">
            Import
          </Button>
        </div>
        
        {/* Divider */}
        <hr class="border-t border-gray-700 mx-4" />
        
        {/* Section 3: Imports count and download icon */}
        <div class="px-4 py-2 flex justify-between items-center text-sm text-gray-400">
          {isOfficial ? (
            <span class="text-gray-500 flex items-center">
              @morphs
              <CheckmarkIcon2 size="w-3 h-3" />
            </span>
          ) : (
            <span></span> // Empty span to maintain layout for non-official characters
          )}
          <div class="flex items-center">
            <div class="flex items-center mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
              </svg>
              {character.imports?.length || 0}
            </div>
            {character.parent && (
              <div class="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 cursor-pointer"
                  fill="none"
                  viewBox="0 0 16 16"
                  stroke="currentColor"
                  onMouseEnter={() => setHoveredParent(character._id)}
                  onMouseLeave={() => setHoveredParent(null)}
                >
                  <path fill-rule="evenodd" d="M5 3.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm0 2.122a2.25 2.25 0 10-1.5 0v.878A2.25 2.25 0 005.75 8.5h1.5v2.128a2.251 2.251 0 101.5 0V8.5h1.5a2.25 2.25 0 002.25-2.25v-.878a2.25 2.25 0 10-1.5 0v.878a.75.75 0 01-.75.75h-4.5A.75.75 0 015 6.25v-.878zm3.75 7.378a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm3-8.75a.75.75 0 100-1.5.75.75 0 000 1.5z" />
                </svg>
                {hoveredParent() === character._id && (
                  <div class="absolute right-0 bottom-full mb-1 bg-gray-800 text-white text-xs py-1 px-2 rounded shadow">
                    {character.parent}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Page>
      <div class="flex justify-between items-center mb-4">
        <PageHeader title="Character Hub" />
        <div class="flex">
          <Button
            onClick={() => setActiveTab('official')}
            class={`mr-2 ${activeTab() === 'official' ? 'bg-red-600' : 'bg-gray-600'}`}
          >
            Official
          </Button>
          <Button
            onClick={() => setActiveTab('original')}
            class={`mr-2 ${activeTab() === 'original' ? 'bg-red-600' : 'bg-gray-600'}`}
          >
            Original
          </Button>
          <Button
            onClick={() => setActiveTab('variations')}
            class={activeTab() === 'variations' ? 'bg-red-600' : 'bg-gray-600'}
          >
            Forks
          </Button>
        </div>
      </div>
      <Show when={activeTab() === 'official'}>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          <For each={officialCharacters()}>
            {renderCharacterCard}
          </For>
        </div>
      </Show>
      <Show when={activeTab() === 'original'}>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          <For each={originalCharacters()}>
            {renderCharacterCard}
          </For>
        </div>
      </Show>
      <Show when={activeTab() === 'variations'}>
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          <For each={variationCharacters()}>
            {renderCharacterCard}
          </For>
        </div>
      </Show>
    </Page>
  )
}

export default Charhub
