import {
  Component,
  createSignal,
  createMemo,
  onMount,
  For,
  Show,
} from 'solid-js'
import { userStore } from '../store'
import PageHeader from '../shared/PageHeader'
import TextInput from '../shared/TextInput'
import Button from '../shared/Button'
import { A } from '@solidjs/router'
import { AppSchema } from 'common/types/schema'
import { Page } from '/web/Layout'
import Loading from '/web/shared/Loading'
import { Users } from 'lucide-solid'

const Explore: Component = () => {
  const [search, setSearch] = createSignal('')
  const [users, setUsers] = createSignal<AppSchema.User[]>([])
  const [loading, setLoading] = createSignal(false)
  const { user, jwt } = userStore()

  const fetchUsers = async () => {
    if (!jwt) {
      console.error('JWT token is missing')
      return
    }

    setLoading(true)
    try {
      const response = await fetch(`/api/users?search=${encodeURIComponent(search())}`, {
        headers: {
          'Authorization': `Bearer ${jwt}`
        }
      })

      if (!response.ok) {
        console.error('Failed to fetch users:', response.statusText)
        setLoading(false)
        return
      }

      const data = await response.json()
      setUsers(data.users)
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }

  onMount(() => {
    if (user) {
      fetchUsers()
    } else {
      console.error('User is not authenticated')
    }
  })

  const filteredUsers = createMemo(() => {
    return users().filter(user => user.username.toLowerCase().includes(search().toLowerCase()))
  })

  const isFollowingMe = (targetUserId: string) => {
    return user?.followers?.includes(targetUserId) || false
  }

  return (
    <Page>
      <PageHeader title="Explore Users" />
      <div class="mb-4 flex justify-between">
        <div class="flex flex-wrap">
          <div class="m-1 ml-0 mr-1">
            <TextInput
              fieldName="search"
              placeholder="Search by username..."
              onKeyUp={(ev) => setSearch(ev.currentTarget.value)}
            />
          </div>
          <div class="py-1">
            <Button onClick={fetchUsers}>Search</Button>
          </div>
        </div>
      </div>
      <Show when={loading()}>
        <div class="flex justify-center">
          <Loading />
        </div>
      </Show>
      <Show when={!loading() && filteredUsers().length === 0}>
        <div class="mt-16 flex w-full justify-center rounded-full text-xl">
          No users found
        </div>
      </Show>
      <Show when={!loading() && filteredUsers().length > 0}>
        <div class="grid w-full grid-cols-[repeat(auto-fit,minmax(160px,1fr))] flex-row flex-wrap justify-start gap-2 py-2">
          <For each={filteredUsers()}>
            {(user) => (
              <UserCard user={user} isFollowingMe={isFollowingMe(user._id)} />
            )}
          </For>
        </div>
      </Show>
    </Page>
  )
}

const UserCard: Component<{ user: AppSchema.User; isFollowingMe: boolean }> = (props) => {
  const { user, jwt } = userStore()
  const [isFollowing, setIsFollowing] = createSignal(user?.following?.includes(props.user._id) || false)

  const toggleFollow = async () => {
    if (!jwt) {
      console.error('JWT token is missing')
      return
    }

    try {
      const endpoint = isFollowing() ? 'unfollow' : 'follow'
      const response = await fetch(`/api/user/${user?._id}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify({ targetUserId: props.user._id })
      })

      if (response.ok) {
        setIsFollowing(!isFollowing())
        // Update the user's following array in the userStore
        userStore.updateUser({
          following: isFollowing()
            ? user?.following?.filter(id => id !== props.user._id)
            : [...(user?.following || []), props.user._id]
        })
      } else {
        console.error(`Failed to ${endpoint} user:`, response.statusText)
      }
    } catch (error) {
      console.error(`Error ${isFollowing() ? 'unfollowing' : 'following'} user:`, error)
    }
  }

  return (
    <div class="bg-800 flex flex-col items-center justify-between gap-1 rounded-lg border-[1px] border-[var(--bg-700)]">
      <A
        href={`/user/${props.user._id}`}
        class="flex flex-col items-center w-full"
      >
        <div class="bg-700 flex h-32 w-full items-center justify-center rounded-lg rounded-b-none">
          <Users size={48} />
        </div>
        <div class="w-full p-2 text-sm">
          <div class="overflow-hidden text-ellipsis whitespace-nowrap text-center font-bold">
            {props.user.username}
          </div>
        </div>
      </A>
      <Button
        onClick={toggleFollow}
        class="mb-2"
        schema={isFollowing() ? 'hollow' : 'primary'}
      >
        {isFollowing() ? 'Following' : props.isFollowingMe ? 'Follow back' : 'Follow'}
      </Button>
    </div>
  )
}

export default Explore